import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountsModule } from './accounts/accounts.module';
import { MainCompComponent } from './components/main-comp/main-comp.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '', loadChildren:()=>import('./accounts/accounts.module').then(m=>m.AccountsModule) },
  {
    path: 'main', component: MainCompComponent, children: [
      { path: 'dashboard', loadChildren:()=>import('./components/dashboard/dashboard.module').then(m=>m.DashboardModule) },
      { path: '', loadChildren: ()=>import('./components/profile/profile.module').then(m=>m.ProfileModule) },
      { path: '', loadChildren: ()=>import('./components/space-section/space-section.module').then(m=>m.SpaceSectionModule) },
      { path: '', loadChildren: ()=>import('./components/user-section/user-section.module').then(m=>m.UserSectionModule) }, 
      { path: '', loadChildren: ()=>import('./components/booking-section/booking-section.module').then(m=>m.BookingSectionModule) }, 
      { path: '', loadChildren: ()=>import('./components/reservation-section/reservation-section.module').then(m=>m.ReservationSectionModule) }, 
      { path: '', loadChildren: () => import('./components/transaction-section/transaction-section.module').then(m => m.TransactionSectionModule) },
      { path: '', loadChildren: () => import('./components/leads/leads.module').then(m => m.LeadsModule) }, 
      { path: '', loadChildren: ()=>import('./components/community-section/community-section.module').then(m=>m.CommunitySectionModule) },
      { path: '', loadChildren: ()=>import('./components/help-section/help-section.module').then(m=>m.HelpSectionModule) },
      { path: '', loadChildren: ()=>import('./components/peeves/peeves.module').then(m=>m.PeevesModule)},
      { path: '', loadChildren: ()=>import('./components/map-surfing/map-surfing.module').then(m=>m.MapSurfingModule)},
      { path: '', loadChildren: ()=>import('./components/front-end/front-end.module').then(m=>m.FrontEndModule)},
      { path: '', loadChildren: ()=>import('./components/corporate/corporate.module').then(m=>m.CorporateModule)},
      { path: '', loadChildren: ()=>import('./components/business-partners/business-partners.module').then(m=>m.BusinessPartnersModule)},
      { path: '', loadChildren: ()=>import('./components/administrator-section/administrator-section.module').then(m=>m.AdministratorSectionModule) },
      { path: '', loadChildren:()=>import('./components/termination/termination.module').then(m=>m.TerminationModule) }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
